import { Title } from '@solidjs/meta';
import { createAsync, useNavigate, useSearchParams } from '@solidjs/router';
import { cachedGet } from '@troon/api-client';
import { ActivityIndicator, Button, Heading, Input, Label, Link, TextField } from '@troon/ui';
import debounce from 'debounce';
import { For, Show, Suspense } from 'solid-js';
import { IconSearchMagnifyingGlass } from '@troon/icons/search-magnifying-glass';
import { IconUserAdd } from '@troon/icons/user-add';
import { Content } from '../../../../../components/main-content';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../../../../components/table';
import { TroonAccessTag } from '../../../../../components/troon-access-tag';
import type { RouteSectionProps } from '@solidjs/router';

export default function Customers(props: RouteSectionProps) {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams<{ query?: string }>();

	const results = createAsync(async () => {
		const q = (searchParams.query as string) ?? undefined;
		if (q) {
			return searchUsers({ query: { query: q } });
		}
		return null;
	});

	const updateQuery = debounce((value: string) => {
		setSearchParams({ query: value });
	}, 500);

	return (
		<Content>
			<Title>Search for Customers | Troon Operator</Title>
			<Heading as="h1">Customers</Heading>

			<TextField name="query" class="w-full">
				<Label>Search for a customer</Label>
				<Input
					type="search"
					onInput={(e) => {
						updateQuery(e.target.value);
					}}
					value={searchParams.query || ''}
					class="p-6 ps-12"
					placeholder="Search by name, email, phone or rewards number"
					prefixElement={<IconSearchMagnifyingGlass class="mx-2 text-brand" />}
				/>
			</TextField>

			<div class="flex flex-col gap-4">
				<div class="flex flex-wrap items-end justify-between gap-4">
					<Heading as="h2" size="h4">
						Results
					</Heading>
					<Button as={Link} href={`/facility/${props.params.facility!}/customer/new`} class="size-fit grow-0">
						<IconUserAdd /> New User
					</Button>
				</div>

				<Table>
					<Thead>
						<Tr>
							<Th>Name</Th>
							<Th>Rewards ID</Th>
							<Th>Email address</Th>
							<Th>Rewards Level</Th>
							<Th>Access</Th>
							<Th>Location</Th>
						</Tr>
					</Thead>
					<Tbody>
						<Suspense
							fallback={
								<tr>
									<td colspan={6} class="text-center italic text-neutral-700">
										<ActivityIndicator />
									</td>
								</tr>
							}
						>
							<Show
								when={results.latest?.users}
								fallback={
									<Tr>
										<Td colspan={6} class="text-center italic text-neutral-700">
											<Show when={!searchParams.query} fallback={<ActivityIndicator />}>
												Enter a search query to get started.
											</Show>
										</Td>
									</Tr>
								}
							>
								<For
									each={results()?.users}
									fallback={
										<Tr>
											<Td colspan={6} class="rounded-b border border-t-0 border-neutral bg-white p-8 text-center">
												No results for <q class="font-medium">{searchParams.query}</q>.
											</Td>
										</Tr>
									}
								>
									{(user) => (
										<Tr
											class="group cursor-pointer"
											onClick={() => {
												navigate(`/facility/${props.params.facility}/customer/${user.rewardsId}`, {
													state: { query: searchParams.query },
												});
											}}
										>
											<Th scope="row">
												<Link
													href={`/facility/${props.params.facility}/customer/${user.rewardsId}`}
													state={{ query: searchParams.query }}
													class="inset-0 after:absolute"
												>
													{user.firstName} {user.lastName}
												</Link>
											</Th>
											<Td>{user.rewardsId}</Td>
											<Td>{user.email}</Td>
											<Td>{user.rewardsLevel}</Td>
											<Td>
												<TroonAccessTag product={user.troonAccessProductType} />
											</Td>
											<Td>{[user.city, user.state, user.country].filter(Boolean).join(', ')}</Td>
										</Tr>
									)}
								</For>
							</Show>
						</Suspense>
					</Tbody>
				</Table>
			</div>
		</Content>
	);
}

const searchUsers = cachedGet('/v0/operator/users/search', {});
